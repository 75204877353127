import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: {
      title: "首页",
      keepAlive: true,
    }
  },
  {
    path: '/faceAuth',
    name: 'FaceAuth',
    component: () => import(/* webpackChunkName: "about" */ '../views/credit/faceAuth.vue'),
    meta: {
      title: "人脸认证",
      keepAlive: true,
    }
  },
  {
    path: '/work',
    name: 'Img',
    component: () => import(/* webpackChunkName: "about" */ '../views/temp/Img.vue'),
    meta: {
      title: "推广信息",
      keepAlive: true,
    }
  },
  {
    path: '/work2',
    name: 'TempIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/temp/index.vue'),
    meta: {
      title: "工单列表",
      keepAlive: true,
    }
  },
  {
    path: '/workDetail',
    name: 'TempDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/temp/Detail.vue'),
    meta: {
      title: "工单详情",
      keepAlive: false,
    }
  },
]



const router = new VueRouter({
  mode: 'history',
  // 如果vue.config.js中publicPath打开,这里也同时打开,两边必须同时使用
  // base: process.env.NODE_ENV === "production" ? "/app" : "",
  base: "",
  routes
})

// 后置路由
router.afterEach((to, from) => {
  // 赋值title
  document.title = to.meta.title;
  // 回到顶部
  window.scrollTo(0, 0);
})

// 解决两次访问相同路由地址报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
